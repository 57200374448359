/** @jsxImportSource @emotion/react */

import { GetDocumentDetailsDocument } from "../../../graphql/generated/types";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import DocumentDetailsProvider from "../../../contexts/documents";
import { FC } from "react";
import SkeletonDetails from "../../../components/Details/components/SkeletonDetails";
import DocumentFlow from "./components/DocumentFlow";
import DetailsWrapper from "../../../components/Details/components/Main/DetailsWrapper";
import { documentDetailsTabRoutes } from "../../../Routes";
import Sidebar from "../../../components/Sidebar";
import DetailsMenu from "../../../components/Details/components/Header/DetailsTabs";
import { ColorNames } from "../../../theme";

const DocumentDetails: FC = () => {
  const { pathname } = useLocation();
  const { docId: paramsId } = useParams();
  const { data, loading, refetch } = useQuery(GetDocumentDetailsDocument, {
    variables: {
      docId: paramsId,
    },
  });

  const detailsTabContent = (
    <Sidebar
      TopComponent={DetailsMenu}
      menuItems={[
        {
          title: pathname.endsWith("chat") ? "Chat" : "Notas",
          Component: Outlet,
          color: ColorNames.GREEN,
        },
      ]}
    ></Sidebar>
  );

  if (loading) return <SkeletonDetails />;

  return (
    <DocumentDetailsProvider documentData={data?.document} refetch={refetch}>
      <DetailsWrapper
        detailsTabRoutes={documentDetailsTabRoutes}
        detailsTabContent={detailsTabContent}
      >
        <DocumentFlow />
      </DetailsWrapper>
    </DocumentDetailsProvider>
  );
};

export default DocumentDetails;
