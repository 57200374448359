import React, { useEffect } from "react";
import ListView from "../../../components/ListView/ListView";
import { DocumentsTableData, mapTableData } from "./tableColumns";
import {
  ListDocumentsQuery,
  useCountDocumentsQuery,
  useListDocumentsQuery,
} from "../../../graphql/generated/types";
import { useTableContext } from "../../../contexts/table";
import DocumentCard from "../DocumentCards/DocumentCard";
import { ToDocumentDetailsLocation } from "../../../Locations";
import UploadDocumentModal from "./UploadDocumentModal";

interface DocumentsTableProps<TFilter> {
  filter: TFilter;
}

const DocumentsTable = <TFilter extends { composeQuery: () => any[][] }>({
  filter,
}: DocumentsTableProps<TFilter>) => {
  const { paginator, actions, setData } = useTableContext();

  const documentsQuery = useListDocumentsQuery({
    variables: {
      filter: filter.composeQuery(),
      sort: { [actions.sort.sortKey]: actions.sort.direction },
      limit: paginator.limit,
      skip: paginator.skip,
    },
  });

  const countDocumentsQuery = useCountDocumentsQuery({
    variables: {
      filter: filter.composeQuery(),
    },
  });

  useEffect(() => {
    if (documentsQuery.data?.documents) {
      setData(documentsQuery.data?.documents);
    }
  }, [documentsQuery]);

  const refetchData = () => {
    documentsQuery.refetch();
  };

  return (
    <ListView<DocumentsTableData, ListDocumentsQuery["documents"][number]>
      CardComponent={DocumentCard}
      loading={documentsQuery.loading}
      redirectLocation={ToDocumentDetailsLocation}
      mapTableData={mapTableData}
      total={countDocumentsQuery.data?.countDocuments || 0}
      uploadModal={<UploadDocumentModal refetch={refetchData} />}
    />
  );
};

export default DocumentsTable;
