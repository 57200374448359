/** @jsxImportSource @emotion/react */
import React from "react";
import TableSwitch from "./actions/TableSwitch";
import { css } from "@emotion/react";
import MultiSelectInput from "../../Input/SelectInputs/MultiSelectInput";
import Button from "../../Button";
import { IconNames } from "../../Icons/styles/iconNames";
import Icon from "../../Icons";
import { useModalStore } from "../../Modal/context/ModalStore";
import { useTableContext } from "../../../contexts/table";
import ActionList from "../../ActionsList";

interface TableActionsProps {
  uploadModal: React.ReactNode;
}

const getStyles = () => ({
  container: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,
  right: css`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  resetButton: css`
    position: relative;
    > span {
      transform: scaleX(-1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    svg {
      cursor: pointer;
    }
  `,
});

const TableActions: React.FC<TableActionsProps> = ({ uploadModal }) => {
  const styles = getStyles();
  const { openModal } = useModalStore();
  const { actions } = useTableContext();
  const {
    activeView,
    selectedColumns,
    tableColumnsOptions,
    selectedRows,
    onTableSwitchViewClick,
    onTableColumnsSelect,
    onResetColumnsClick,
    onDownloadCSVClick,
  } = actions;

  const nSelectedRows = Object.keys(selectedRows).reduce(
    (prev, key) => prev + (selectedRows[key] ? 1 : 0),
    0
  );

  return (
    <div css={styles.container}>
      <TableSwitch active={activeView} onClick={onTableSwitchViewClick} />
      <div css={styles.right}>
        <ActionList
          label={`AÇÕES (${nSelectedRows} SELECIONADOS)`}
          actions={[
            {
              label: "Test Action",
              onClick: () => console.log("Test Action!"),
            },
          ]}
        />
        {uploadModal ? (
          <Button
            text="Upload"
            width={130}
            iconName={IconNames.Upload}
            onClick={() =>
              openModal(uploadModal, undefined, {
                style: {
                  height: 400,
                  width: 500,
                  top: 0,
                  left: 0,
                  right: 0,
                  margin: "auto",
                },
              })
            }
          />
        ) : null}

        <Button
          text="CSV"
          width={90}
          iconName={IconNames.Download}
          onClick={onDownloadCSVClick}
        />
        {activeView === "table" ? (
          <>
            <MultiSelectInput
              onSelect={onTableColumnsSelect}
              value={selectedColumns.map(({ id }) => id)}
              inputName="columnsSelect"
              placeholder="Vizualisar Colunas"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              options={tableColumnsOptions}
            />
            <Button
              customStyle={styles.resetButton}
              width={45}
              icon={<Icon name={IconNames.Redo} />}
              onClick={onResetColumnsClick}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TableActions;
